































































































































import {HttpClient} from '@/services/http.service';
import {HomepageStore} from '@/store/modules/homepage';
import {UiConfigStore} from '@/store/modules/ui-config';
import {Component, Ref, Vue} from 'vue-property-decorator';

@Component
export default class HomeContactUs extends Vue {

  @Ref('contact-form')
  contactForm: Record<'reset', () => void>;

  get logo(): string {
    return UiConfigStore.logoContrast;
  }

  get businessEmail(): string {
    return HomepageStore.businessEmail;
  }

  get helpEmail(): string {
    return HomepageStore.helpEmail;
  }

  get image(): string {
    return HomepageStore.contactUsImage;
  }

  formModel = false;

  dialog = false;

  form: Record<string, { model: string; validators: unknown[] }> = {
    name: {
      model: '',
      validators: [
        (value: string) => !!value || this.$t('ps.general.contact-us.name-required')
      ]
    },
    phone: {
      model: '',
      validators: [
        (value: string) => !!value || this.$t('ps.general.contact-us.phone-required')
      ]
    },
    email: {
      model: '',
      validators: [
        (value: string) => !!value || this.$t('ps.general.contact-us.email-required')
      ]
    },
    company: {
      model: '',
      validators: [
        (value: string) => !!value || this.$t('ps.general.contact-us.company-required')
      ]
    },
    country: {
      model: '',
      validators: [
        (value: string) => !!value || this.$t('ps.general.contact-us.country-required')
      ]
    },
    message: {
      model: '',
      validators: []
    }
  };

  sendEmail(): void {
    if (this.formModel) {
      HttpClient.post('/emails', this.getFormValue()).then(() => {
        this.contactForm.reset();
        this.dialog = true;
      });
    }
  }

  private getFormValue(): Record<string, string> {
    const value: Record<string, string> = {};
    Object.keys(this.form)
      .forEach(key => {
        value[key] = this.form[key].model;
      });
    return value;
  }

}
